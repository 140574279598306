import { Col, Row } from 'antd'
import React, { FC } from 'react'
import { H4 } from '../../../../shared/components/Typography'
import { PayloadProperty } from '../../components/PayloadProperty'
import { CodeBlock, LeftCol, Paragraph, RightColServiceStatus, SectionRow, Container } from '../../Styles'

export const WebhookEvents: FC = () => {
  return (
    <SectionRow>
      <LeftCol span="12">
        <H4 id="service-status-event" style={{ marginLeft: '-40px' }}>
          Service Info Webhooks
        </H4>
        <Container style={{ marginLeft: '-40px' }}>
          <Row>
            <Col span="24">
              <PayloadProperty
                name="serviceOrderNumber"
                type="string"
                required={true}
                description={`Unique identification number provided to a request for servicing an equipment.`}
                example={'323329491'}
              />
              <PayloadProperty
                name="customerPurchaseOrderNumber"
                type="string"
                description={`Number that uniquely identifies a purchasing document.This number creates the link between the customer purchase order and the sales document.`}
              />
              <PayloadProperty
                name="equipmentNumber"
                type="string"
                required={true}
                description={`KEN (KONE/ NON-KONE equipment number) for the equipment.`}
                example={'42069879'}
              />
              <PayloadProperty
                name="technicianRegistrationNumber"
                type="string"
                description={`Unique number of a technician that can be provided as means of indentity.`}
              />
              <PayloadProperty
                name="actualArrivalDateTime"
                type="string (date-time)"
                description={`Date whereby the execution of a work order by a technician begins.`}
              />
              <PayloadProperty
                name="finishedDateTime"
                type="string (date-time)"
                description={`Date whereby the execution of a work order by a technician ends.`}
              />
              <PayloadProperty
                name="createdDateTime"
                type="string (date-time)"
                description={`Date and time when planned and unplanned service order is created.`}
              />
              <PayloadProperty
                name="jobDescription"
                type="string"
                description={`Feedback given by technician in unplanned Service Orders.`}
              />
              <PayloadProperty
                name="equipmentFailureReason"
                type="string"
                description={`Documented code after troubleshooting a failed equipment.`}
              />
              <PayloadProperty
                name="equipmentFailureReasonDescription"
                type="string"
                description={`Documented description after troubleshooting a failed equipment.`}
              />
              <PayloadProperty
                name="failedComponentCode"
                type="string"
                description={`Code of components which are mostly impacted.`}
              />
              <PayloadProperty
                name="failedComponentDesc"
                type="string"
                description={`Descriptions of components which are mostly impacted.`}
              />
              <PayloadProperty
                name="equipmentConditionOnArrival"
                type="string"
                description={`Code for analyzed condition of an equipment on arrival of technician that has been reported failed.`}
              />
              <PayloadProperty
                name="equipmentConditionOnArrivalDesc"
                type="string"
                description={`Description of analyzed condition of an equipment when techinician arrives on site where equipment has been reported failed.`}
              />
              <PayloadProperty
                name="equipmentConditionOnDeparture"
                type="string"
                description={`Code for the state of an equipment being repaired at the time when technician exits the equipment location.`}
              />
              <PayloadProperty
                name="equipmentConditionOnDepartureDesc"
                type="string"
                description={`Description of the state of an equipment being repaired at the time when technician exits the failed equipment location.`}
              />
              <PayloadProperty
                name="technicianActionCode"
                type="string"
                description={`Code of the action being performed by a technician toward repairing a failed equipment.`}
              />
              <PayloadProperty
                name="technicianActionDescription"
                type="string"
                description={`Description of the action being performed by a technician toward repairing a failed equipment.`}
              />
              <PayloadProperty
                name="entrapment"
                type="boolean"
                description={`Indication that someone is inside an elevator after it's operation stopped.`}
              />
              <PayloadProperty
                name="serviceContractNumber"
                type="string"
                description={`Number with which service orders were sold. It can include multiple service orders.`}
              />
              <PayloadProperty
                name="salesOrderNumber"
                type="string"
                description={`SalesOrderNumber represents the contract between the customer and sales organization. It is the legal document number for delivery and supply
                 of specified goods and services. SalesOrderNumber is available for YSM6 Fixed Price Repair Order service order type.`}
              />
              <PayloadProperty
                name="earliestStartDate"
                type="string (date-time)"
                description={`Planned date for technician to start working on a service order.`}
              />
              <PayloadProperty
                name="workOrderPlannedStartDate"
                type="string (date-time)"
                description={`Date on which the technician will be able to arrive at the site for resolving equipment issue for planned service orders. 
                Note that the technician may start earlier than this date.`}
              />
              {/* adding workCenter in documentation */}
              <PayloadProperty
                name="workCenter"
                type="string"
                description={'Number allocated to a geographical mapping within which an equipment is located.'}
                example={'2374022K, 2374015, 23740224'}
              />
              <PayloadProperty
                name="serviceOrderType"
                type="string"
                description={`Code for the type of service order requested during the creation of the service order like YSM1 or YSM2.`}
                example={`YSM1, YSM2, YSM3,YSM4,YSM6`}
                stringEnums={[
                  'YSM1 = Planned Service Order with Contract',
                  'YSM2 = Planned Service Order without Contract',
                  'YSM3 = Unplanned Service Order with Contract',
                  'YSM4 = Unplanned Service Order without Contract',
                  'YSM6 = Fixed Price Repair Order',
                ]}
              />
              <PayloadProperty
                name="serviceOrderTypeDesc"
                type="string"
                description={`Description for the type of service order requested during the creation of the service order like YSM1 or YSM2.`}
              />
              <PayloadProperty
                name="orderStatus"
                type="string"
                description={`The state of a service order with respect to its progress towards completion for a specific equipment.`}
                stringEnums={[
                  'New',
                  'Hold',
                  'Unreachable',
                  'Dispatched',
                  'Returned',
                  'Received',
                  'On Route',
                  'Arrived',
                  'Started',
                  'Reassigned',
                  'WIP',
                  'Interrupted',
                  'Continued',
                  'Finished',
                  'Closed',
                  'Voice Mail',
                  'Cancelled',
                  'Initiated',
                  'Accepted',
                  'Rejected',
                  'Delivered',
                  'Gateway Reset',
                  'Sent to KFM',
                  'Confirm',
                  'Leave Site',
                  'Forward',
                  'Scheduled',
                ]}
              />
              <PayloadProperty
                name="description"
                type="string"
                description={'Description of the service order and the potential issue.'}
              />
              <PayloadProperty
                name="assemblyLocationCode"
                type="string"
                description={
                  'Code for the sub parts of an equipment which are divided into separate units (assemblies).'
                }
                example={'KCO_INSP_REPORT '}
              />
              <PayloadProperty name="customer" type="string" description={'It is the customer account number.'} />
              <PayloadProperty
                name="startDate"
                type="string (date-time)"
                description={'Date on which a service order was created after issue was reported or detected.'}
              />
              <PayloadProperty name="companyCode" type="string" description={'It is the frontline company code.'} />

              <PayloadProperty
                name="activityTypeCode"
                type="string"
                description={
                  'Documented code representing the type of maintenance visit provided like planned or unplanned repair, billable or non-billable repair or inspections etc.'
                }
              />
              <PayloadProperty
                name="activityType"
                type="string"
                description={
                  'Description of the type of maintenance visit provided like planned or unplanned repair, billable or non-billable repair, inspections etc.'
                }
                example={`Callout, Troubleshooting, Preventive check, Repair, Clinica, Maintenance, Inspection, Warranty, Equipment Condition Survey, Work Cancelled, KCC Service Spare Part`}
                stringEnumDescriptions={[
                  {
                    value: `Callout`,
                    description: `An unplanned visit (reported by equipment user or by KONE remote monitoring system).`,
                  },
                  {
                    value: `Troubleshooting`,
                    description: `Urgent resolution visit based on automated issue detection (Only for installations with KONE 24/7 Connected Service).`,
                  },
                  {
                    value: `Preventive check`,
                    description: `Planned maintenance visits based on anomaly detected (Only for installations with KONE 24/7 Connected Service).`,
                  },
                  {
                    value: `Repair`,
                    description: `Planned or unplanned repairs.`,
                  },
                  {
                    value: `Clinica`,
                    description: `Planned visit based on equipment statistics (includes exhaustive analysis of equipment safety and operation and is covered by the contract).`,
                  },
                  {
                    value: `Maintenance`,
                    description: `Planned regular maintenance visits.`,
                  },
                  {
                    value: `Inspection`,
                    description: `Accompanying visit with inspector or resolution of issue from inspection report about an equipment.`,
                  },
                ]}
              />
              <PayloadProperty name="lineItem" type="array of object" />
              <Row style={{ marginLeft: '120px', width: '90%' }}>
                <Col span="22">
                  <PayloadProperty
                    name="operationText"
                    type="string"
                    description={
                      'This text describes the Operation or Sub-operation to be performed by the technician.'
                    }
                  />
                </Col>
              </Row>
              <PayloadProperty
                name="id"
                type="string"
                required={true}
                description={`unique subscription id of the event which belongs to particular subscription only`}
              />
              <PayloadProperty
                name="time"
                type="string"
                required={true}
                description={`ISO 8601 timestamp of when the events received.`}
              />
              <PayloadProperty
                name="type"
                type="string"
                required={true}
                description={` define the type of event`}
                example={'com.kone.equipment.serviceorder.v2'}
              />

              <PayloadProperty
                name="equipmentId"
                type="string"
                required={true}
                description={`KEN (KONE/ NON-KONE equipment number) for the equipment with ‘ken:’ prefix`}
                example={'ken:42069879'}
              />
              <PayloadProperty
                name="equipment­Info"
                type="object of type EquipmentInfo"
                description={`Equipment information, for example location and description. This field is an optional field. Some fields may or may not be present  `}
              />
              <Row style={{ marginLeft: '120px', width: '90%' }}>
                <Col span="22">
                  <PayloadProperty name="serial­Number" type="string" description={'Equipment serial number'} />
                  <PayloadProperty name="equipmentNumber" type="string" />
                  <PayloadProperty
                    name="type"
                    type="string"
                    description={'Equipment type'}
                    stringEnums={[
                      'Building',
                      'Autowalk',
                      'Building Door',
                      'Dumbwaiter',
                      'Elevator',
                      'Escalator',
                      'Stairlift',
                      'Facadator',
                      'Marine Elevator',
                      'Marine Escalator',
                      'Marine other eq.',
                      'Other',
                    ]}
                  />
                  <PayloadProperty name="city" type="string" description={'City name'} />
                  <PayloadProperty name="countryCode" type="string" description={'Code of Country'} />
                  <PayloadProperty name="­street" type="string" description={`Street name`} />
                  <PayloadProperty name="postalCode" type="string" description={'Postal code'} />
                  <PayloadProperty name="name" type="string" description={`Description of the equipment`} />
                  <PayloadProperty name="region" type="string" description={'Region code'} />
                  <PayloadProperty
                    name="customerEquipmentNumber"
                    type="string"
                    description={
                      'Unique id provided by customer as an internal identification of the equipment within their system'
                    }
                  />
                </Col>
              </Row>
              <PayloadProperty
                name="clientid"
                type="string"
                required={true}
                description={`The application client ID.`}
                example={'e2b5a2aa-71d1-4bea-b340-9d85f8d88c29'}
              />
              <PayloadProperty
                name="source"
                type="string"
                required={true}
                description={`Endpoint of the webhook subscription.`}
                example={'http://dev.kone.com/webhook/765b2708-7036-4d1e-964e-cbd2b8d3fdaa'}
              />
              <PayloadProperty
                name="specversion"
                type="string"
                required={true}
                description={`This is the version of payload schema, this version will change when payload changes.`}
                example={'1.0'}
              />

              {/* <PayloadProperty
                name="callerName"
                type="string"
                description={`Name of the person reporting the failure of an equipment.`}
              /> */}
              {/* <PayloadProperty
                name="technicianName"
                type="string"
                description={`Name of the technician repairing the failed equipment.`}
              /> */}

              {/* <PayloadProperty
                name="additionalCommentsToCustomer"
                type="string"
                description={'This is the comment made by technician for customer during visit.'}
              /> */}
              {/* <PayloadProperty
                name="plannerGroupCode"
                type="string"
                description={
                  'Group of technicians and equipment. Technicians can work on the equipment which is in the same group.'
                }
                example={`015, 004, 017`}
              /> */}
              {/* <PayloadProperty
                name="customerAssetName" //inventory
                type="string"
                description={'Equipment location ID from Customer.'}
              /> */}
            </Col>
          </Row>
        </Container>
      </LeftCol>
      <RightColServiceStatus span="12">
        <Paragraph>Event example</Paragraph>
        <CodeBlock language="json" large={true}>
          {`
{
  "data": {
    "serviceOrderNumber": "9ABE41986389",
    "customerPurchaseOrderNumber": "1911848",
    "equipmentNumber": "44414120",
    "technicianRegistrationNumber": "64084997",
    "actualArrivalDateTime": "2022-11-10T17:56:52Z",
    "finishedDateTime": "2022-11-10T18:18:47Z",
    "createdDateTime": "2022-06-30T18:30:00Z",
    "jobDescription": "Fermatorsturing defect na algemene stroomonderbreking in de regio.",
    "equipmentFailureReason": "20",
    "equipmentFailureReasonDescription": "Problème technique, électrique Technisch probleem, elektrisch ",
    "failedComponentCode": "10600",
    "failedComponentDesc": "10600 Besturingssysteem  Besturingsmodules",
    "equipmentConditionOnArrival": "09",
    "equipmentConditionOnArrivalDesc": "Running - Operational malfunction",
    "equipmentConditionOnDeparture": "01",
    "equipmentConditionOnDepartureDesc": "Equipment running, back to normal operation",
    "technicianActionCode": "92",
    "technicianActionDescription": "Secure or stop the equipment",
    "entrapment": false,
    "serviceContractNumber": "E00427660",
    "salesOrderNumber" : "341021871",
    "earliestStartDate": "2022-11-30T02:57.27Z",
    "workCenter": "281CE401",
    "serviceOrderType": "YSM3",
    "serviceOrderTypeDesc": "Unplanned Service Order with Contract",
    "orderStatus": "Finished",
    "description": "105  Buiten dienst op niveau met gesloten deur",
    "assemblyLocationCode": "SN_CALLIN",
    "customer": "13786904",
    "workOrderPlannedStartDate": "2020-06-09T00:00:00.000+0000",
    "startDate": "2022-11-10T00:00:00Z",
    "companyCode": "BEL",
    "activityTypeCode": "Y01",
    "activityType": "Repair",
    "lineItem":[
      {
        "operationText":'some operationText'
      }]
  },
  "id": "71fee4f4-34bc-46e9-b7b7-620faf1a50e3",
  "time": "2023-09-09T04:42:51.893Z",
  "type": "com.kone.equipment.serviceorder.v2",
  "equipmentId": "ken:42069879",
  "equipmentInfo": {
    "equipmentNumber": "42069879",
    "serialNumber": "NOT AVAILABLE",
    "type": "Elevator",
    "city": ["COURBEVOIE"],
    "countryCode": "FR",
    "street": "12 RUE DU GAL AUDRAN",
    "postalCode": "92400",
    "district": null,
    "houseNumber": null,
    "name": ["1230 - AL92.044 - AG3E - M1V", null, null],
    "region": "92",
    "customerEquipmentNumber": "PRG1230-AL92.044-AG3E-M1V"
  },
  "clientid": "e2b5a2aa-71d1-4bea-b340-9d85f8d88c29",
  "source": "http://dev.kone.com/webhook/765b2708-7036-4d1e-964e-cbd2b8d3fdaa",
  "specversion": "1.0"
}
`}
        </CodeBlock>
      </RightColServiceStatus>
    </SectionRow>
  )
}
