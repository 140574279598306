import React, { FC, useEffect, useState } from 'react'
import { CreateFormStrings, PartnerStrings } from '../../../../shared/strings/PartnersContent'
import Modal from '../../../../shared/components/ModalPopUp'
import { Col, Row } from 'antd'
import { Contact } from '../../Models'
import {
  AddContactButton,
  ButtonContainer,
  CancelButton,
  ContactHeader,
  Container,
  FormLabel,
  InputColumn,
  InputName,
  InputsContainer,
  PartnerP,
  RemoveIconContact,
  SaveButton,
  StyledLine,
} from './Styles'
import { ErrorMessage, Toast } from '../../../../shared/components'
import { SalesPageAPI } from '../../../../shared/api/salespage'
import { checkDuplicateContacts } from '../../../../../src/shared/utils/salespage'
import * as _ from 'lodash'
export interface EditContactInfoProps {
  isEditContactInfoOpen: boolean
  closeEditContactInfo: () => void
  contactInfo?: any
  isCreateErrorVisible: boolean | false
  setIsCreateErrorVisible: (data: boolean) => void
  createIntegratorErrorMessage?: string | ''
  setCreateIntegratorErrorMessage: (msg: string) => void
  invalidateIntegratorData: () => void
  setIsSuccess: (data: boolean) => void
  setSuccessMsg: (data: string) => void
  partnerId: string
}
const EditContactInfo: FC<EditContactInfoProps> = (props) => {
  const defaultContact: Contact = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    partnerContactId: null,
  }
  const [contacts, setContacts] = useState<Contact[]>([])
  const [deleteContactIds, setDeleteContacts] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState<boolean | false>(false)
  const [successMsg, setSuccessMsg] = useState<string | ''>('')
  const [isSuccessOnSave, setIsSuccessOnSave] = useState<boolean | false>(false)
  const [successMsgOnSave, setSuccessMsgOnSave] = useState<string | ''>('')
  const defaultError = {
    firstName: {
      error: false,
      message: 'Please enter first name',
    },
    lastName: {
      error: false,
      message: 'Please enter last name',
    },
    email: {
      error: false,
      message: 'Please enter a valid email',
    },
    phoneNumber: {
      error: false,
      message: 'Please enter a valid phone number',
    },
    jobTitle: {
      error: false,
      message: 'Please enter a job title',
    },
  }
  const [formErr, setFormErr] = useState<any>([])
  const {
    isEditContactInfoOpen,
    closeEditContactInfo,
    contactInfo,
    setIsCreateErrorVisible,
    setCreateIntegratorErrorMessage,
    invalidateIntegratorData,
    partnerId,
  } = props

  useEffect(() => {
    const contactValues: Contact[] = []
    contactInfo?.map((contact: any) => {
      contactValues?.push({
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        phoneNumber: contact?.phoneNumber?.join(','),
        jobTitle: contact?.jobTitle,
        partnerContactId: contact?.partnerContactId,
      })
    })
    setContacts(contactValues && contactValues.length ? contactValues : [{}])
    console.log('value', contactValues)
  }, [])

  const onClickHandle = async () => {
    const isValid = validateContact()
    if (!isValid) {
      return false
    }

    const contactInfo = _.filter(
      contacts.map((contact) => {
        const c: {
          partnerContactId?: string
          firstName?: string
          lastName?: string
          email?: string
          phoneNumber?: string | string[]
          jobTitle?: string
        } = { ...contact }
        if (contact.phoneNumber) {
          c.phoneNumber = contact.phoneNumber.split(',').map((s: string) => s.trim())
        } else {
          delete c.phoneNumber
        }
        return c
      }),
      (c) => !_.isEmpty(c)
    )

    const hasDuplicateSolution = checkDuplicateContacts(contactInfo as Contact[], 'email')
    if (hasDuplicateSolution) {
      setSuccessMsg(PartnerStrings.uniqueEmail)
      setIsSuccess(true)
      return false
    } else {
      setIsSuccess(false)
    }

    const payload: {
      partnerStep: number
      deleteContactIds: string[]
      contactInfo?: Record<string, string | string[]>[]
    } = {
      partnerStep: 3,
      deleteContactIds,
    }

    if (!_.isEmpty(contactInfo)) {
      payload['contactInfo'] = contactInfo
    }

    const resopnse = await SalesPageAPI.editIntegrator(payload, partnerId)
    if (!resopnse.ok) {
      const newRes = await resopnse.json()
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage(newRes?.message)
    } else if (resopnse.status === 202) {
      const newRes = await resopnse.json()
      setSuccessMsgOnSave('Intergrator updated successfully.')
      setIsSuccessOnSave(true)
      setTimeout(() => {
        closeEditContactInfo()
        invalidateIntegratorData()
      }, 2000)
    }
    return true
  }

  useEffect(() => {
    if (contacts) {
      const hasDuplicateSolution = checkDuplicateContacts(contacts, 'email')
      if (hasDuplicateSolution) {
        setSuccessMsg(PartnerStrings.uniqueEmail)
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
    }
  }, [contacts])

  const removeContact = (index: number, partnerContactId: string | null) => {
    console.log('partnerContactId...', partnerContactId)
    const tempContacts = [...contacts]
    tempContacts.splice(index, 1)
    console.log('tempContacts', tempContacts)
    setContacts(tempContacts && tempContacts.length ? tempContacts : [{}])
    if (partnerContactId) {
      setDeleteContacts([...deleteContactIds, partnerContactId])
    }
  }

  const validateCheck = () => {
    const contactNames = new Set()

    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i]

      if (
        contact.firstName === '' ||
        contact.lastName === '' ||
        contact.email === '' ||
        contact.phoneNumber === '' ||
        contact.jobTitle === '' ||
        contactNames.has(contact.firstName)
      ) {
        return false
      }

      contactNames.add(contact.firstName)
    }

    return true
  }

  const validateContact = () => {
    const errorFlags: boolean[] = []
    const tempError: any = formErr.map((err: any) => ({ ...err })) // Deep copy each element

    const contractInfo = _.filter(contacts, (c) => !_.isEmpty(c))

    contractInfo.forEach((contact: Contact, index: number) => {
      if (_.isEmpty(tempError[index])) {
        tempError[index] = { ...defaultError }
      }
      const emailRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (contact.email && !emailRegx.test(contact.email)) {
        tempError[index] = {
          ...tempError[index],
          email: { ...tempError[index].email, error: true },
        }
        errorFlags.push(false)
      } else {
        tempError[index] = {
          ...tempError[index],
          email: { ...tempError[index].email, error: false },
        }
      }

      const regx = /^([+]?)+\d{8,15}(,[\s\s]?([+]?)+\d{8,15})*$/
      if (contact.phoneNumber && !regx.test(contact.phoneNumber)) {
        tempError[index] = {
          ...tempError[index],
          phoneNumber: { ...tempError[index].phoneNumber, error: true },
        }
        errorFlags.push(false)
      } else {
        tempError[index] = {
          ...tempError[index],
          phoneNumber: { ...tempError[index].phoneNumber, error: false },
        }
      }
    })

    setFormErr(tempError)
    return !errorFlags.includes(false)
  }

  const checkTouched = (index: number, key: string, value: string) => {
    if (formErr[index]?.[key].error === true && value && value?.length > 0) {
      const newFormErr = [...formErr]
      if (newFormErr[index]?.[key].error) {
        newFormErr[index][key].error = false
        setFormErr(newFormErr)
      }
    }
  }

  const onInputChange = (e: any, index: number, key: string) => {
    const { value } = e.target
    const tempContacts: Contact[] = [...contacts]
    tempContacts[index] = { ...tempContacts[index], [key]: value }
    if (key !== 'phoneNumber') checkTouched(index, key, value)
    setContacts(tempContacts)
    return true
  }

  const addNewContact = () => {
    const contractInfo = _.filter(contacts, (c) => !_.isEmpty(c))

    if (!contractInfo.length) {
      setIsCreateErrorVisible(true)
      setCreateIntegratorErrorMessage('Please Fill atleast one contact details.')
      return
    }

    const isValid = validateContact()
    if (!isValid) {
      return false
    }
    setContacts((preState: any) => [...preState, {}])
    setFormErr((preState: any) => [...preState, { ...defaultError }])
    return true
  }

  const getContactContent = () => {
    const contactContent = contacts.map((contact: Contact, index: number) => (
      <InputsContainer key={`contact_tr_${index}`}>
        <ContactHeader>
          <RemoveIconContact
            onClick={() => removeContact(index, contact?.partnerContactId ? contact?.partnerContactId : null)}
          />
        </ContactHeader>
        <Row gutter={24}>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="first-name-input-js" id="first-name-input-label-js">
                {CreateFormStrings.firstName}
              </FormLabel>
              <InputName
                id="first-name-input-js"
                onChange={(e: any) => onInputChange(e, index, 'firstName')}
                disabled={false}
                value={contact?.firstName}
              />
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="last-name-input-js" id="last-name-input-label-js">
                {CreateFormStrings.lastName}
              </FormLabel>
              <InputName
                id="last-name-input-js"
                onChange={(e: any) => onInputChange(e, index, 'lastName')}
                disabled={false}
                value={contact?.lastName}
              />
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="email-input-js" id="email-input-label-js">
                {CreateFormStrings.email}
              </FormLabel>
              <InputName
                type="email"
                required
                id="email-input-js"
                onChange={(e: any) => onInputChange(e, index, 'email')}
                disabled={false}
                value={contact?.email}
              />
              {formErr?.[index] && formErr[index].email?.error && (
                <ErrorMessage>{formErr[index].email?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="phone-number-input-js" id="phone-number-input-label-js">
                {CreateFormStrings.phoneNumber}
              </FormLabel>
              <InputName
                id="phone-number-input-js"
                onChange={(e: any) => onInputChange(e, index, 'phoneNumber')}
                disabled={false}
                value={contact?.phoneNumber}
              />
              {formErr?.[index] && formErr[index].phoneNumber?.error && (
                <ErrorMessage>{formErr[index].phoneNumber?.message}</ErrorMessage>
              )}
            </InputColumn>
          </Col>
          <Col span={8}>
            <InputColumn>
              <FormLabel htmlFor="job-title-input-js" id="job-title-input-label-js">
                {CreateFormStrings.jobTitle}
              </FormLabel>
              <InputName
                id="job-title-input-js"
                onChange={(e: any) => onInputChange(e, index, 'jobTitle')}
                disabled={false}
                value={contact?.jobTitle}
              />
            </InputColumn>
          </Col>
        </Row>
        <StyledLine />
      </InputsContainer>
    ))
    return contactContent
  }

  return (
    <>
      <Modal
        key="edit-contact-info"
        isOpen={isEditContactInfoOpen}
        title={PartnerStrings.editContactInfo}
        onClose={closeEditContactInfo}
        width={'900px'}
        height={'300px'}
      >
        <Container>
          <PartnerP>{PartnerStrings.contactInformation}</PartnerP>
          <StyledLine />
          {getContactContent()}
        </Container>

        <ButtonContainer>
          <AddContactButton onClick={addNewContact}>{PartnerStrings.addOneMoreContact}</AddContactButton>

          <CancelButton onClick={closeEditContactInfo}>{PartnerStrings.cancel}</CancelButton>
          <SaveButton onClick={onClickHandle}>{PartnerStrings.saveChanges}</SaveButton>
        </ButtonContainer>
      </Modal>
      <Toast isVisible={isSuccess} text={successMsg} onDismiss={() => setIsSuccess(false)} type="error" />
      <Toast
        isVisible={isSuccessOnSave}
        text={successMsgOnSave}
        onDismiss={() => setIsSuccessOnSave(false)}
        type="success"
      />
    </>
  )
}

export default EditContactInfo
