import styled from 'styled-components'
import { Checkbox, Space } from 'antd'
import { color, size } from '../../shared/utils/styles'
import Search from 'antd/lib/input/Search'
import { H6 } from '../../shared/components'
import removeTag from '../../assets/icon-tag-remove.svg'
import { ReactComponent as Gold } from '../../shared/assets/gold.svg'
import { ReactComponent as Silver } from '../../shared/assets/silver.svg'

interface BackgroundContainerProps {
  bgImage: string
}

export const HeaderContainer = styled.div`
  text-align: center;
  position: relative;
  background-color: ${color.gray85};
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
  margin-top: 1%;
  padding-top: 3%;
  padding-bottom: 4%;

  @media (min-width: ${size.large}) {
    display: flex;
  }

  h1 {
    color: white;
    margin-bottom: 0px;
  }
`
export const SearchBy = styled.div`
  color: white;
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin-bottom: 7%;
  margin-top: 7%;
`
export const Radio = styled.div`
  color: white;
`
export const SelectSolution = styled.div`
  color: white;
  margin-right: 15px;
`
export const StyledLine = styled.hr`
  border: 0.5px solid ${color.gray70};
  margin-bottom: 0px;
  opacity: 0.4;
`
export const StyledLineBottom = styled.hr`
  border: 2px solid #0071b9;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-top: 0px;
  width: 90px;
`
export const StyledLineTop = styled.hr`
  border: 2px solid white;
  margin-top: 0px;
  width: 300px;
`
export const Refine = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 2%;
  margin-bottom: 3%;
  color: white;
  width: 20%;
`
export const Card1 = styled.div`
  background-color: 'black';
  div {
    display: flex;
    flex-flow: column;
    margin-top: 2%;
    margin-bottom: 3%;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  border: 1px solid #000;
  padding: 10px;
  background-color: #ffffff;
  // max-width: 250px;
  margin: 5px;
`
export const TileImageClass = styled.div<BackgroundContainerProps>`
  width: 200px;
  height: 200px;
  background-image: url(${(props) => props.bgImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
export const TileImageText = styled.div`
  max-height: 50px;
  max-width: 200px;
  margin-top: 10px;
  font-size: 16px;
  color: #000;
  // background-color: gray;
  padding: 10px;
  letter-spacing: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`

export const TextTileSol = styled.div`
  width: 180px;
  height: 135px;
  padding: 1em 0px;
  margin-right: 3em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  background-color: ${color.white};
  text-align: center;
  position: relative;
  box-sizing: border-box;
`

export const DescOnHoverSol = styled.div`
  width: 180px;
  height: 135px;
  background-color: ${color.white};
  text-align: center;
  position: absolute;
  z-index: 9;
  top: 0px;
  left: -15px
  font-size: 12px;
  height: 100%;
  padding-top: 1em;
  padding-right: 1em;
  margin-left: -15px;
  justify-content: center;
`
export const CheckBox = styled.div`
  color: white;
`
export const StyleCard = styled.div`
  width: 230px;
  display: flex;
  flex-flow: column;
  background-color: #11161a;
  border: 1px solid #3d464c;
  padding-top: 10px;
  margin-top: 2%;
  border-radius: 3%;
`
export const Selector = styled.div`
  max-height: 240px;
  overflow-y: scroll;
`

export const FilterCheckbox = styled(Checkbox)`
  color: white;

  .ant-checkbox-inner {
    background-color: #11161a;
    border-color: #0071b9;
  }
`
export const FilterSearch = styled(Search)`
  .ant-input {
    background-color: #11161a;
    border-color: #3d464c;
  }
  .ant-btn {
    background-color: #11161a;
    border-color: #3d464c;
  }
  .anticon {
    color: #0071b9;
  }
  .ant-input-group-addon {
    background-color: black;
  }

  .ant-input {
    color: white;
  }
`

export const Para = styled.p`
  font-size: 11px;
  color: ${color.gray95};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 64%;
`

export const DescOnHover = styled.div`
  width: 200px;
  height: 200px;
  background-color: ${color.white};
  text-align: center;
  position: absolute;
  z-index: 9;
  top: 10px;
  left: -15px
  font-size: 18px;
  justify-content: center;
  box-sizing: border-box;
`

export const TileImage = styled.img`
  width: 8em;
  height: 5em;
  margin-top: 0.5em;
`
export const LoadingPartnerData = styled.div`
  color: white;
`
export const DescriptionText = styled(H6)`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 5px;
  color: black;
`
export const NoRecordContainter = styled.div`
  height: 70vh;
  color: white;
`

export const ChipContainer = styled.div`
  display: flex;
  margin-left: 6%;
  padding: 30px;
`
export const ChipSpace = styled(Space)``

export const TagRemove = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 2.5px;
  background-size: 10px;
  background-image: url(${removeTag});
  background-repeat: no-repeat;
`
export const Tag = styled.div`
  color: ${color.white};
  background-color: ${color.blueBase};
  border-radius: 4px;
  margin: 5px;
  font-size: 12px;
  padding: 7px 7px 5px 9px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
`
export const gold = styled(Gold)`
  padding: 1%;
  height: 25px;
`
export const silver = styled(Silver)`
  padding: 1%;
  height: 25px;
`
export const Tier = styled.div`
  position: absolute;
  top: -9px;
  left: 159px;
  z-index: 10;
`
