export const PartnerStrings = {
  PartnerCatelouge: 'INTEGRATOR CATALOGUE',
  loadingPartnersList: 'Loading partner list...',
  partnerListError: 'Cannot fetch partner list',
  AddNewMember: 'ADD Integrator',
  partnerInformation: 'Integrator Bio',
  partnerInformation1: 'Integrator Name',
  contactInfotmation: 'Integrator Contact',
  solutionInformation: 'Solution Information',
  others: 'Others',
  integratorLinks: 'Add Integrator Links',
  fileNameError: 'File Name must be between 1 to 64 characters',
  enterVideoUrl: 'Add YouTube Video URL',
  enterDocumentUrl: 'Add Document URL',
  isValidYoutube: 'Incorrect YouTube URL',
  isValidDocUrl: 'Incorrect URL',
  fileLabel: 'File Name',
  urlLabel: 'URL',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  uniqueContactName: 'Please enter a unique contact name',
  jobTitle: 'Job Title',
  enterFileName: 'Enter File Name',
  enterVideoUrlPlaceHolder: 'Enter YouTube URL',
  enterDocumentUrlPlaceHolder: 'Enter Document URL',
  sendButton: 'THANK YOU!!!',
  contactInformation: 'Contact Information',
  selectApi: 'Select API',
  countryRegionAvlForDistribution: 'Countries/regions available for distribution',
  supportLanguageText: 'Sales & after-sales support languages',
  solutionHighlights: 'Solution Highlights',
  editsolutionHighlights: 'Edit Solution Highlights',
  keyCustomerSegments: 'Key Customer Segments',
  apiUsed: 'API used',
  edit: 'EDIT',
  cancel: 'CANCEL',
  send: 'SEND',
  saveChanges: 'Save Changes',
  editCustomerSegments: 'Edit Customer Segments',
  editCountry: 'Edit Country',
  editApis: `Edit API's`,
  editLanguages: `Edit Languages`,
  editCompanyInfoAndContactDetails: 'Edit Company Info',
  editContactInfo: 'Edit Contact Details',
  addOneMoreContact: 'Add More Contact',
  addOneMoreSolution: 'Add More Solution',
  maxSolution: 'Maximum solutions reached',
  solutionCategory: 'Solution Category',
  integratorTier: 'Integrator Tier',
  IntegratorSolution: 'Integrator Solutions',
  editIntegratorLinks: 'Integrator Links',
  videoTitle: 'Videos',
  documentTitle: 'Documents',
  solutionName: 'Solution Name',
  contactDetails: 'Contact Details',
  solutionCenter: 'Solution Center of Knowledge',
  companyInfo: 'Company info',
  country: 'Country',
  integratorContactDetails: 'Integrator Contact Details',
  requireFieldText: '* indicates required fields',
  editSolutionInfo: 'Edit Integrator Solutions',
  uniqueSolutionName: 'Please enter a unique solution name',
  uniqueEmail: 'Email id already exists',
  solutionNameError: 'Solution name must be between 1 to 64 characters',
  requiredFieldText: '* indicates required fields',
  successMessage: 'Intergrator updated successfully',
  partnerLinkError: 'File Name must be between 1 to 64 characters',
  missingFields: 'Please Fill Some Details first',
}

export const DeletePartner = {
  ConfirmDeletion: 'Back',
  Delete: 'Delete',
  DeletePopupText: 'Are you sure you want to delete this integrator ?',
}
export const TileCreateStrings = {
  addNewMember: 'ADD NEW INTEGRATOR',
  addMember: 'ADD MEMBER',
  knowMore: 'KNOW MORE',
}

export const CreateFormStrings = {
  partnerName: 'Integrator name',
  partnerNameRequiredError: 'Integrator name is required',
  nameRequiredError: 'Name is required',
  emailRequiredError: 'Email is required',
  phonenumberRequiredError: 'Phone number is required',
  companyInfoRequiredError: 'Company info is required',
  descriptionRequiredError: 'Description is required',
  partnerCreatedSuccess: 'Integrator created!',
  createPartner: 'Create Integrator',
  createPartnerError: 'Create Integrator error',
  externalOrgType: 'Customer',
  internalOrgType: 'Internal',
  sandboxOrgType: 'Sandbox',
  partnerNameLengthError: 'Integrator name must be between 1 to 64 characters',
  partnerNameWhiteSpaceError: 'Whitespace is not allowed at the beginning and end of the integrator name',
  companyInfoMaxLengthError: 'Comapany Info must be between 1 to 1000 characters',
  companyInfoMinLengthError: 'Comapnay Name too short',
  companyInfo: 'Company Info',
  websiteLink: 'Website Link',
  websiteValidation: 'Website must start with https://',
  solutionHighlight: 'Solution Highlight',
  solutionName: `Solution Name`,
  keyCustomerSegments: 'Key Customer Segments',
  customerSegments: 'Customer Segments',
  firstName: 'First Name',
  name: 'Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  phoneNumberMax: 'Phone Number too long',
  phoneNumberMin: 'Phone Number Lenght too less',
  invalidPhoneNumber: 'Invalid phone number',
  jobTitle: 'Job Title',
  selectApi: 'Select API',
  countryRegionAvlForDistribution: 'Countries/regions available for distribution',
  selectCountry: 'Location of Headquarter',
  country: 'Country',
  selectLanguages: 'Select Languages',
  selectCategory: 'Select Category',
  selectByTier: 'Select By Tier',
  description: 'Description',
  websiteLinkRequiredError: 'Please enter a valid URL',
  languageRequiredError: 'Please select atleast one language',
  tierRequiredError: 'Please select tier',
  countryRequiredError: 'Please select Location of Headquarter',
  uploadLogoRequiredError: 'Please select upload logo image',
  selectRegion: 'Select Region',
  sendMeACopy: 'Send Me A Copy',
  title: 'Thank you for your message',
  message: 'We have received your message and will get back to you as needed.',
  messageToPartner: 'Your message is sent to the integrator.',
  emailPartnerPopUpTitle: 'Send email to the Integrator',
  emailToKoneSupport: 'Send us a message!! Our team is here to help',
  emailToKoneSupportLabel: 'Contact Us: Support @KONE',
  solutionMaterialReel: 'Solution Material Reel',
  videos: 'Checkout Integrator Videos !',
  documentTitle: 'Go through Integrator Documents',
  back: 'Ok, Return To Home Page',
  consent: 'I consent that my personal information will be collected for quality and audit purpose. Visit our ',
  privacyStatement: 'Privacy Statement',
  privacyStatementLink: 'https://www.kone.com/en/privacy-statement.aspx',
}

export const EditConpanyInfoStrings = {
  contactCanNotEmpty: 'Contact can not be empty.',
}
