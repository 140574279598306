import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogDecember24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        December 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        December release notes summarizes latest changes for Service Info API, Equipment Status API and Authentication
        API.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>
              The workTime and workTimeUnit fields are now removed from both{' '}
              <Link to={Paths.ServiceInfoAPIV2Docs.path + '#getServiceOrderById'}> Service Info REST API</Link> and{' '}
              <Link to={Paths.ServiceInfoWebhookAPIApiPageDocs.path}> Service Info Webhook API </Link>.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Authentication API</li>
          <UL>
            <li>
              Added a new <Link to={Paths.AuthenticationAPIV2.path + '#ResourceDetails'}> Resource Details</Link> API to
              fetch products accessible to a specific ken.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              Updated equipmentStatus fields in{' '}
              <Link to={Paths.EquipmentStatus2RestApiPageDocs.path + '#getEquipmentEntrapmentByIds'}>
                {' '}
                Get equipment status information
              </Link>{' '}
              REST API.
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
