import React from 'react'
import { H4, OL, P, UL } from '../../../../shared/components/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import Paths from '../../../Paths'

export const ReleaseNotesLogNov24 = () => {
  return (
    <>
      <H4 id="equipment-status" style={{ marginLeft: '-30px', marginTop: '40px' }}>
        November 2024
      </H4>
      <P style={{ marginLeft: '-30px' }}>
        November release notes summarizes latest changes for API Portal and bug fixes.
        <P style={{ fontWeight: 'bold' }}>What is new here:</P>
        <OL size="small">
          <li style={{ marginTop: '30px' }}>API Portal</li>
          <UL>
            <li>
              Updated the terminal ID description for{' '}
              <Link to={Paths.ElevatorWebsocketAPIV2.path + '#make-an-elevator-call'}>Elevator Call API</Link> and also
              updated the event payload example for the config for terminals.
            </li>
            <li>
              The <Link to={Paths.SolutionOverview.path}> Solution Creation</Link> page has been updated with separate
              sections for Solution Development, Solution Validation and Solution Deployment.{' '}
            </li>
            <li>
              Inside the Kone APIs folder, Solution Example and Github Example folder link for each API added. For
              example - <Link to={Paths.ElevatorCallAPI.path}> Elevator Call API</Link>
            </li>
            <li>
              The{' '}
              <a
                href="https://github.com/konecorp/kone-api-examples/tree/main/src/examples"
                target="_blank"
                rel="noreferrer"
              >
                kone-api-examples
              </a>{' '}
              folder is now updated by API Products, such as the Elevator Call API and the Operational APIs.
            </li>
          </UL>
          <li style={{ marginTop: '30px' }}>Service Info API</li>
          <UL>
            <li>Bug fixes for failedComponentDesc in Service Info webhook API.</li>
          </UL>
          <li style={{ marginTop: '30px' }}>Equipment Status API</li>
          <UL>
            <li>
              The field <q>customerEquipmentLocationId</q> is now available in{' '}
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#availability-event'}>
                {' '}
                Equipment Availability Information Webhook API
              </Link>{' '}
              ,
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentAvailabilityByIds'}>
                {' '}
                Equipment Availability REST API
              </Link>{' '}
              ,
              <Link to={Paths.EquipmentStatus2WebhookEvents.path + '#movement-event'}>
                {' '}
                Equipment Movement information Webhook API
              </Link>{' '}
              and
              <Link to={Paths.EquipmentStatus2RestAPIDocs.path + '#getEquipmentMovementByIds'}>
                {' '}
                Equipment Movement Information REST API
              </Link>{' '}
              .
            </li>
          </UL>
        </OL>
      </P>
    </>
  )
}
